<template>
    <div class="calls-to-action">
        <CraftLink
            v-if="!!primary && (primary.url || primary.element)"
            :link="primary"
            :size="size"
            icon="arrow-right"
            appearance="button"
            :measure-gtm-cta="true"
        >
            {{ primary.text }}
        </CraftLink>

        <CraftLink
            v-if="!!secondary && (secondary.url || secondary.element)"
            :link="secondary"
            :size="size"
            class="base-button--secondary"
            icon="arrow-right"
            appearance="button"
            :measure-gtm-cta="true"
        >
            {{ secondary.text }}
        </CraftLink>
    </div>
</template>

<script setup>
defineProps({
    primary: {
        type: Object,
        default: () => {},
        required: false
    },
    secondary: {
        type: Object,
        default: () => {},
        required: false
    },
    size: {
        type: String,
        default: 'medium',
        validator: (value) => ['small', 'medium', 'large'].includes(value)
    }
});
</script>

<style lang="less">
.calls-to-action {
    display: flex;
    gap: .625rem;
    flex-wrap: wrap;

    .base-button {
        white-space: nowrap;
        text-decoration: none;
        margin: 0;
    }
}
</style>
