<template>
    <BaseHero
        :title="entry.heroTitle || entry.title"
        :description="entry.heroDescription ?? ''"
        :images="entry.heroImages"
        :layout="layout"
        background-style="light"
    >
        <template #preTitle>
            <slot name="preTitle">
                <EntryMetadata
                    v-if="!!entry.contentType || !!entry.dossiers"
                    :entry="entry"
                    class="entry-metadata--hero"
                />
            </slot>
        </template>

        <template
            #default
        >
            <CallsToAction
                v-if="entry.callToAction"
                :primary="entry.callToAction"
                size="large"
            />
            <slot v-else />
        </template>
    </BaseHero>
</template>

<script setup>
defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
    layout: {
        type: String,
        default: 'narrow',
        validator: value => ['narrow', 'wide/2-columns'].includes(value)
    }
});
</script>
