<template>
    <BaseSection
        class="content-wall"
        section-width="content"
    >
        <BaseIcon
            class="content-wall__icon"
            icon="lock"
        />

        <h2 class="content-wall__title">{{ title }}</h2>

        <BaseRichText
            class="content-wall__content"
            :content="content"
        />

        <section class="content-wall__actions">
            <BaseButton
                v-if="!loggedIn"
                :element="NuxtLink"
                :to="'/auth/login'"
                :external="true"
                title="login"
                size="large"
                icon="arrow-right"
                @click="useGtm().trackEvent({
                    event: 'cta_click',
                    ctaText: 'Inloggen',
                    ctaUrl: '/mijn-tln/login'
                })"
            >
                Inloggen
            </BaseButton>

            <BaseButton
                :element="NuxtLink"
                :to="`/${navigationStore.signupEntry.uri}`"
                class="base-button--secondary"
                size="large"
                icon="arrow-right"
                @click="useGtm().trackEvent({
                    event: 'cta_click',
                    ctaText: 'Sluit je aan',
                    ctaUrl: `/${navigationStore.signupEntry.uri}`
                })"
            >
                Sluit je aan
            </BaseButton>
        </section>
    </BaseSection>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import { useNavigationStore } from '~/store/navigation';
import { useGtm } from '@gtm-support/vue-gtm';

const authStore = useAuthStore();
const navigationStore = useNavigationStore();

const { internationalOnly, fenexOnly } = defineProps({
    internationalOnly: {
        type: Boolean,
        default: false
    },
    fenexOnly: {
        type: Boolean,
        default: false
    }
});

const NuxtLink = resolveComponent('NuxtLink');

const loggedIn = computed(() => {
    return authStore.loggedIn;
});

const title = computed(() => {
    if (internationalOnly) {
        return 'Deze content is exclusief voor leden van TLN-internationaal';
    }

    if (fenexOnly) {
        return 'Deze content is exclusief voor Fenex-leden';
    }

    return 'Deze content is exclusief voor TLN-leden';
});

const content = computed(() => {
    if (internationalOnly) {
        return `<p>
            De content op deze pagina is alleen zichtbaar als je ingelogd bent als TLN-internationaal lid. Opereer jij als logistiek- of transportbedrijf internationaal? Dan is het lidmaatschap van TLN-internationaal iets voor jou! Als lid krijg je toegang tot ons netwerk en profiteer je van handige kennis, tools en afspraken met partners tegen een scherpe prijs.
        </p>`;
    }

    if (fenexOnly) {
        return `<p>
            De content op deze pagina is alleen zichtbaar als je ingelogd bent als Fenex-lid. Fenex behartigt als brancheorganisatie de belangen van expediteurs. Door lid te worden krijg je toegang tot ons netwerk en profiteer je van handige kennis, tools en afspraken met partners tegen een scherpe prijs.
        </p>`;
    }

    return `<p>
        De content op deze pagina is alleen zichtbaar als je ingelogd bent als TLN-lid. Nog geen lid? Samen staan we sterker. Met een lidmaatschap krijg je toegang tot ons netwerk en onze kennis. Je praat mee over de CAO, blijft op de hoogte en profiteert van handige voorbeeldcontracten, tools en afspraken met partners tegen een scherpe prijs.
    </p>`;
});
</script>

<style lang="less" src="./ContentWall.less"></style>
