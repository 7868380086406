<template>
    <BaseSection
        v-if="itemsLoaded && items.length > 0"
        class="section--index section--no-padding"
        data-width="content"
        title="Direct naar"
    >
        <BaseButton
            id="toggle-anchors-button"
            class="base-button--secondary"
            size="small"
            @click="anchorsVisible = !anchorsVisible"
        >
            {{ anchorsVisible ? 'Inhoudsopgave verbergen' : 'Inhoudsopgave bekijken' }}
        </BaseButton>

        <AnchorList
            class="anchor-list--index"
            :data-visible="anchorsVisible"
            appearance="index"
            :items="items"
        />
    </BaseSection>
</template>

<script setup>
import slugify from '~/utils/slugify';

const items = ref([]);
const anchorsVisible = ref(false);
const itemsLoaded = ref(false);

onMounted(() => {
    if (toValue(itemsLoaded) !== true) {
        loadIndexItems();
    }
});

function loadIndexItems() {
    prepareHeadingTags();
    populateIndexItems();
    itemsLoaded.value = true;
}

function prepareHeadingTags() {
    if (process.server) {
        return;
    }

    const headings = document.querySelectorAll('.base-rich-text h2');

    headings.forEach((headingTag) => {
        const id = slugify(headingTag.innerHTML);

        if (document.getElementById(id)) {
            return;
        }

        const anchor = document.createElement('a');
        anchor.classList.add('anchor-list-anchor');
        anchor.id = slugify(headingTag.innerHTML);
        anchor.dataset.label = headingTag.innerHTML;

        headingTag.before(anchor);
    });
}

function populateIndexItems() {
    if (process.server) {
        return;
    }

    const anchors = document.querySelectorAll('.anchor-list-anchor');

    items.value = [...anchors].map((anchorTag, index) => {
        return {
            label: `${index + 1}. ${anchorTag.dataset.label}`,
            ref: anchorTag.id
        };
    });
}
</script>

<style lang="less">
#toggle-anchors-button {
    margin-bottom: 1rem;

    @media @q-sm-min {
        display: none;
    }

}

@media @q-sm-max {
    .section--index {
        .section__title {
            display: none;
        }

        .section__body {
            margin-top: 0;
        }
    }
}
</style>
