<template>
    <ul
        class="anchor-list"
        :class="{
            'anchor-list--index': appearance === 'index'
        }"
    >
        <template
            v-for="item in items"
            :key="item.ref"
        >
            <li
                v-if="item.ref"
                class="anchor-list__item"
            >
                <BaseButton
                    class="base-button base-button--transparent-dark"
                    element="a"
                    :href="`#${item.ref}`"
                >
                    {{ item.label }}
                </BaseButton>
            </li>
        </template>
    </ul>
</template>

<script setup>
defineProps({
    items: {
        type: Array,
        default: () => []
    },
    appearance: {
        type: String,
        default: 'inline',
        validator: (value) => ['index', 'inline'].includes(value)
    }
});
</script>

<style lang="less" src="./AnchorList.less" />
