<template>
    <div class="support-overview">
        <section
            v-if="departments && departments.length > 0"
            class="support-overview__departments"
        >
            <div 
                v-for="department in departments" 
                :key="department.id"
            >
                <h4>Contact de afdeling {{ department.title }}</h4>
                <SupportCard
                    :supporter="department"
                    :is-expert="false"
                />
            </div>
        </section>

        <section v-if="experts && experts.length > 0">
            <h4>Contact de expert<span v-if="experts.length > 1">s</span></h4>
            <div class="support-overview__experts">
                <SupportCard
                    v-for="expert in experts"
                    :key="expert.id"
                    :supporter="expert"
                />
            </div>
        </section>
    </div>
</template>

<script setup>
defineProps({
    experts: {
        type: Array,
        default: () => []
    },
    departments: {
        type: Array,
        default: () => []
    }
});
</script>

<style lang="less" src="./SupportOverview.less" />
